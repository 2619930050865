<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title><v-icon left color="green">group</v-icon>Customer</v-card-title>
                    <v-card-text class="my-0 py-0" :key="refreshCustomerKey">
                        <v-list dense>
                            <v-list-item v-for="(customer, index) in customers" :key="index">
                                <v-list-item-content>
                                    <v-list-item-title v-if="customer.organisation && index === 0">
                                        {{ customer.organisation.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <!-- <v-btn color="red" icon @click="removeCustomer(index)" id="delete-customer"><v-icon>edit</v-icon></v-btn>
                                 -->
                                    <v-btn icon @click="organisationDialog = true, updateCustomer = true, currentIndex=index"
                                        id="delete-customer" v-if="customer.organisation && index === 0"><v-icon>edit</v-icon></v-btn>

                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item v-if="availableCustomerRoles.length > 0 && !showCustomers && customers.length === 0"
                                @click="organisationDialog = true" id="add-customer">
                                <v-list-item-content class="text-center">
                                    <span>Add customer <v-icon color="primary">add_circle_outline</v-icon></span>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-else-if="availableCustomerRoles.length > 0" @click="showCustomers = false">
                                <v-list-item-content class="text-center">
                                    <v-icon>expand_less</v-icon>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
        <v-dialog v-model="organisationDialog" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-spacer></v-spacer>
                    <v-btn text @click="organisationDialog = false, searchOrganisation = null">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field outlined label="Search" v-model="searchOrganisation" dense clearable></v-text-field>
                    <v-progress-linear v-if="savingCustomer" indeterminate color="primary"></v-progress-linear>
                    <v-list dense style="max-height:60vh; overflow-y: auto">
                        <v-list-item v-for="(org, index) in filterOrganisations" :key="index"
                            @click="addCustomer(org.id, org.relatedOrganisation.id)">
                            <v-list-item-content>
                                <v-list-item-title v-if="org.relatedOrganisation">
                                    {{ org.relatedOrganisation.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="filterOrganisations.length == 0">
                            <v-list-item-content class="text-center">
                                <span style="color: grey">No organisations found</span>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>


    </div>
</template>
<script>
export default {
    props: ['profile', 'contacts'],
    data: () => ({
        loading: false,
        items: [],
        organisationDialog: false,
        roles: [
            { name: "Shipper", value: "shipper" },
            { name: "On behalf of Shipper", value: "onBehalfShipper" },
            { name: "Forwarder", value: "forwarder" },
            { name: "On behalf of Forwarder", value: "onBehalfForwarder" },
            { name: "Consignee", value: "consignee" },
            { name: "On behalf of Consignee", value: "onBehalfConsignee" },
            { name: "Buyer", value: "buyer" },
            { name: "On behalf of Buyer", value: "onBehalfBuyer" },
            { name: "Notify", value: "firstNotify" },
            { name: "2nd Notify Party", value: "secondNotify" },
            { name: "Courier", value: "courierParty" },
        ],
        refreshCustomerKey: 2000,
        savingCustomer: false,
        searchOrganisation: null,
        showCustomers: false,
        updateCustomer: false,
        currentIndex: null
    }),
    watch: {
        'profile.consigneeProfileRelationships.length': {
            handler: function (val) {
                this.refreshCustomerKey++
                console.log(this.refreshCustomerKey, val)
            },
            immediate: true
        },
        // profile: {
        //     handler: function (val) {
        //         this.loading = true
        //         this.$nextTick(() => {
        //             this.loading = false
        //         })
        //     },
        //     deep: true
        // }
    },
    computed: {
        availableCustomerRoles() {
            let result = []
            this.roles.forEach(role => {
                if (this.profile[role.value] && !this.profile.consigneeProfileRelationships.map(x => x.customerId).includes(this.profile[role.value + 'Id'])) {
                    result.push({
                        role: role.name,
                        organisationId: this.profile[role.value + 'Id'],
                        organisation: this.profile[role.value]
                    })
                }
            })
            return result.filter(x => x.organisationId != this.$store.state.currentOrg.id)
        },
        customers() {
            let organisations = this.contacts.map(x => x.relatedOrganisation)
            let result = this.profile.consigneeProfileRelationships.map(x => ({
                ...x,
                organisation: organisations.find(y => y.id == x.customerId),
                organisationId: x.customerId
            }))
            return result
        },
        filterOrganisations() {
            let result = this.contacts
            if (this.searchOrganisation) {
                result = result.filter(x => {
                    return x.relatedOrganisation.name.toLowerCase().includes(this.searchOrganisation.toLowerCase())
                })
            }
            return result
        }
    },
    methods: {
        async addCustomer(relationshipId, organisationId) {
            this.savingCustomer = true
            if (this.updateCustomer) {
                let find = this.profile.consigneeProfileRelationships[this.currentIndex]
                let result =await this.$API.updateConsigneeProfileRelationship({
                    customerId: organisationId,
                    id: find.id,

                })
                this.$emit('refreshCustomer', result)
            }
            else {
                if (this.profile.id) {
                    let result = await this.$API.createConsigneeProfileRelationship({
                        customerId: organisationId,
                        consigneeProfileId: this.profile.id,
                        organisationRelationshipId: relationshipId
                    })
                    this.$emit('refreshCustomer', result)
                } else {
                    this.profile.consigneeProfileRelationships.push({
                        customerId: organisationId,
                        consigneeProfileId: this.profile.id
                    })
                    this.$emit('addCustomer', {
                        customerId: organisationId,
                        consigneeProfileId: this.profile.id
                    })
                }
            }
            this.refreshCustomerKey++
            this.organisationDialog = false
            this.updateCustomer=false
            this.searchOrganisation = null
            this.savingCustomer = false


        },
   
        async removeCustomer(index) {
            let find = this.profile.consigneeProfileRelationships[index]
            if (find && find.id) {
                await this.$API.updateConsigneeProfileRelationship({
                    id: find.id,
                    isActive: false,
                    isDeleted: true
                })
                this.$emit('refreshCustomer', find.id)
                this.refreshCustomerKey++
            } else {
                this.profile.consigneeProfileRelationships.splice(index, 1)
                this.refreshCustomerKey++
            }
            // console.log(this.profile.consigneeProfileRelationships)
        },
    }
}
</script>